import { Outlet, useLocation } from "react-router-dom";
import Drawer from "./Drawer";
import MemoDrawer from "./MemoDrawer";
import { useState, useEffect, useContext } from "react";
import Header from "./Header";
import AppBottomNavigation from "./AppBottomNavigation";
import { Context } from "../../utils/Context";
import styled from "styled-components";
import dayjs from "dayjs";
import "dayjs/locale/fr-ca";

var localizedFormat = require("dayjs/plugin/localizedFormat");
const App = styled.div`
  min-height: 100vh;
  min-height: ${(props) => props.vh * 100 + "px"};
  display: flex;
  flex-direction: column;
  background-color: #efeee7;
`;
const Container = styled.div`
  flex: 1;
  padding: 70px 20px;
`;
const Layout = () => {
  dayjs.extend(localizedFormat);
  dayjs.locale("fr-ca");
  const context = useContext(Context);
  const location = useLocation();
  const { loggedUser, getContextUser } = context;
  const { pathname } = location;
  const { vh } = context;
  useEffect(() => {
    if (!loggedUser) {
      getContextUser();
    }
  }, [pathname]);
  return (
    <App vh={vh}>
      <Header />
      <Drawer />
      <MemoDrawer />
      <Container>
        <Outlet />
      </Container>
      <AppBottomNavigation />
    </App>
  );
};

export default Layout;
