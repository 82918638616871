import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const getToken = () => {
  const token = cookies.get("token") || "";
  return token;
};

export const getfeuillesderoute = async (status) => {
  const token = getToken();
  try {
    let response = await axios.get(`/db/getfeuillesderoute/?status=${status}`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};

export const getfeuillederoute = async (id) => {
  const token = getToken();
  try {
    let response = await axios.get(`/db/getfeuillederoute/?id=${id}`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const getfeuillederoutephase = async (id) => {
  const token = getToken();
  try {
    let response = await axios.get(
      `/api/getphasefromdateandid/?date=${id.split("-")[0]}&phaseid=${
        id.split("-")[1]
      }`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};

export const sendfeuillederoute = async (data) => {
  const token = getToken();
  const newData = { ...data };
  newData.photos = data.photos.map((e) => {
    return { name: e.name, url: e.url };
  });
  if (newData.peinture.md_reservoir_blanc_total) {
    newData.peinture.md_reservoir_blanc_total = (
      (newData.peinture.md_reservoir_blanc_total * 2) /
      3
    ).toFixed(2);
  }
  if (newData.peinture.md_reservoir_jaune_total) {
    newData.peinture.md_reservoir_jaune_total = (
      (newData.peinture.md_reservoir_jaune_total * 2) /
      3
    ).toFixed(2);
  }
  if (newData.peinture.md_reservoir_couleurspeciale) {
    newData.peinture.md_reservoir_couleurspeciale =
      newData.peinture.md_reservoir_couleurspeciale.map((e) => ({
        ...e,
        total: ((e.total * 2) / 3).toFixed(2),
      }));
  }
  try {
    let response = await axios.post(
      "/db/sendfeuillederoute/",
      {
        data: newData,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};

export const getemployeslist = async () => {
  const token = getToken();
  try {
    let response = await axios.get("/api/getemployes", {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
