import { Drawer, Button, Space, Avatar, Popconfirm, notification } from "antd";
import { useContext, useState, useEffect } from "react";
import { Context } from "../../utils/Context";
import { CloseOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import { FaTrashAlt } from "react-icons/fa";
import "./quill.css";
const MemoDrawer = () => {
  const context = useContext(Context);
  const { visibleMemoDrawer, setVisibleMemoDrawer } = context;
  const [value, setValue] = useState("");
  const onMemoChange = (e) => {
    localStorage.setItem("memoData", e);
    setValue(e);
  };
  useEffect(() => {
    const oldData = localStorage.getItem("memoData");
    setValue(oldData);
  }, []);
  return (
    <Drawer
      //   title="Basic Drawer"
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
      headerStyle={{
        backgroundColor: "#001529",
        color: "white",
        borderRadius: 0,
      }}
      bodyStyle={{
        backgroundColor: "#001529",
        color: "white",
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
      }}
      width="100%"
      placement="right"
      onClose={() => setVisibleMemoDrawer(false)}
      open={visibleMemoDrawer}
      extra={
        <Button
          icon={<FaTrashAlt />}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            setValue("");
            localStorage.removeItem("memoData");
            notification.success({
              message: "Les notes ont été effacées !",
              placement: "top",
              duration: 1.5,
            });
          }}
        />
      }
    >
      <div style={{ flex: 1 }}>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={onMemoChange}
          style={{ height: "95%" }}
        />
      </div>
    </Drawer>
  );
};

export default MemoDrawer;
