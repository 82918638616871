import axios from "axios";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
const cookies = new Cookies();

const getToken = () => {
  const token = cookies.get("token") || "";
  return token;
};

export const createUser = async ({ employe, password, groupe }) => {
  const token = getToken();
  try {
    let response = await axios.post(
      `/users/createuser`,
      {
        employe,
        password,
        groupe,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    console.log({ err });
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};

export const deleteUser = async ({ username }) => {
  const token = getToken();
  try {
    let response = await axios.post(
      `/users/deleteuser`,
      {
        username,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    console.log({ err });
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};
export const modifyPassword = async ({ username, password }) => {
  const token = getToken();
  try {
    let response = await axios.post(
      `/users/modifypassword`,
      {
        username,
        password,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    return response.data;
  } catch (err) {
    console.log({ err });
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};

export const getAllUsers = async () => {
  const token = getToken();
  try {
    let response = await axios.get(`/users/getallusers`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  } catch (err) {
    console.log({ err });
    if (err.response.status === 401) {
      window.location.href = "/login";
    }
  }
};

export const loginUser = async ({ username, password }) => {
  let response = await axios.post(`/users/login`, { username, password });
  if (response.data.success) {
    const { token } = response.data;
    const jwt = jwtDecode(token);
    cookies.set("token", token, {
      path: "/",
      maxAge: jwt.exp - jwt.iat,
    });
  }
  return response.data;
};

export const logoutUser = async () => {
  await axios.get("/users/logout");
  cookies.remove("token");
  localStorage.removeItem("memoData");
};

export const getUser = async () => {
  const token = getToken();

  let response = await axios.get(`/users/info`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
};
