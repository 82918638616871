import { List, Button, Space, Card, Spin, Tag } from "antd";
import { useEffect, useState, useContext } from "react";
import { Context } from "../utils/Context";
import dayjs from "dayjs";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { getfeuillesderoute } from "../modules/Api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const ListItemContainer = styled.div`
  flex: 1;
  display: flex;
  color: #393939;
  flex-direction: column;
`;

const FeuillesDeRoute = () => {
  const context = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const {
    setHeaderTitle,
    setHeaderType,
    setBottomMenuType,
    setBottomMenuValue,
  } = context;
  const { status } = useParams();
  useEffect(() => {
    setHeaderType("feuillesderoute");
    setBottomMenuType("feuillesderoute");
    setBottomMenuValue(status);
  }, []);
  useEffect(() => {
    getFeuillesDeRoute(status);
  }, [status]);
  const getFeuillesDeRoute = async (status) => {
    setLoading(true);
    const response = await getfeuillesderoute(status);
    setData(response.feuillesDeRoute);
    console.log(response.feuillesDeRoute);
    setLoading(false);
  };
  return (
    <Container>
      <Content>
        {!loading ? (
          <Card>
            <List
              dataSource={data}
              renderItem={(task, index) => (
                <TaskItem task={task} index={index} allTasks={data} />
              )}
            />
          </Card>
        ) : (
          <Spin />
        )}
      </Content>
    </Container>
  );
};
const TaskItem = ({ task, index, allTasks }) => {
  return (
    <Link to={`/feuillederoute/${task.id}/resume`}>
      <List.Item
        style={{
          borderBottom:
            index === allTasks.length - 1 ? "none" : "1px solid #39393925",
        }}>
        <ListItemContainer>
          <Space>
            <strong>Date: </strong>
            {dayjs(task.date).format("YYYY-MM-DD")}
          </Space>
          <Space>
            <strong>Client: </strong>
            {task.client}
          </Space>
          <Space>
            <strong>Projet: </strong>
            {task.noprojet}-{task.descriptionprojet}
          </Space>
          <Space>
            <strong>Phase: </strong>
            {task.descriptionphase}
          </Space>
          <Space>
            <strong>Chargé de projets: </strong>
            {task.chargedeprojet}
          </Space>
          <Space>
            <strong>Remarques: </strong>
            <div style={{ display: "flex", gap: 5, flexWrap: "wrap" }}>
              {task.vehicules.map((vehicule, i) => (
                <Tag key={i} style={{ margin: 0 }}>
                  {`${vehicule.nom.split(" ")[0]} ${
                    vehicule.remarque ? `- ${vehicule.remarque}` : ""
                  }`}
                </Tag>
              ))}
            </div>
          </Space>
        </ListItemContainer>
      </List.Item>
    </Link>
  );
};
export default FeuillesDeRoute;
