import { Drawer, Button, Space, Avatar, Popconfirm } from "antd";
import { useContext } from "react";
import { Context } from "../../utils/Context";
import { CloseOutlined } from "@ant-design/icons";
import { logoutUser } from "../../modules/Users";
const MyDrawer = () => {
  const context = useContext(Context);
  const {
    visibleDrawer,
    setVisibleDrawer,
    loggedUser,
    getContextUser,
    stringToColour,
  } = context;
  return (
    <Drawer
      //   title="Basic Drawer"
      closeIcon={<CloseOutlined style={{ color: "white" }} />}
      headerStyle={{
        backgroundColor: "#001529",
        color: "white",
        borderRadius: 0,
      }}
      bodyStyle={{
        backgroundColor: "#001529",
        color: "white",
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
      }}
      width={250}
      placement="left"
      onClose={() => setVisibleDrawer(false)}
      open={visibleDrawer}
    >
      <div style={{ flex: 1 }}>
        <Button
          type="link"
          href="/feuillesderoute/todo"
          onClick={() => setVisibleDrawer(false)}
          style={{ color: "white", fontSize: 16 }}
        >
          Feuilles de route
        </Button>
      </div>
      <div
        style={{
          backgroundColor: "",
          height: 75,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {loggedUser ? (
          <Popconfirm
            title="Voulez-vous vous déconnecter?"
            onConfirm={async () => {
              logoutUser().then(() => {
                getContextUser();
              });
            }}
            okText="Oui"
            cancelText="Non"
          >
            <Space style={{ cursor: "pointer" }}>
              <Avatar
                size="large"
                style={{
                  backgroundColor: stringToColour(loggedUser.username),
                }}
              >
                {loggedUser.lastname[0].toUpperCase()}
                {loggedUser.firstname && loggedUser.firstname[0].toUpperCase()}
              </Avatar>
              <span>
                {loggedUser.lastname}{" "}
                {loggedUser.firstname && loggedUser.firstname}
              </span>
            </Space>
          </Popconfirm>
        ) : (
          <a
            style={{
              color: "white",
            }}
            href={"/login"}
          >
            <span style={{ textDecoration: "underline" }}>Se connecter</span>
          </a>
        )}
      </div>
    </Drawer>
  );
};

export default MyDrawer;
