import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CheckIcon from '@mui/icons-material/Check';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TagIcon from '@mui/icons-material/Tag';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../utils/Context';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { CheckCircleFilled } from '@ant-design/icons';

const theme = createTheme({
  palette: {
    primary: {
      main: '#001529',
    },
  },
});

const AppBottomNavigation = () => {
  const location = useLocation();
  const { pathname } = location;
  const context = useContext(Context);
  const {
    bottomMenuType,
    bottomMenuValue,
    setBottomMenuValue,
    ressourcesSaved,
    quantitesSaved,
    peintureSaved,
  } = context;
  let navigate = useNavigate();
  const params = useParams();
  switch (bottomMenuType) {
    case 'feuillesderoute':
      return (
        <ThemeProvider theme={theme}>
          <BottomNavigation
            sx={{
              width: '100%',
              position: 'fixed',
              bottom: '0px',
              zIndex: '200',
            }}
            showLabels
            value={bottomMenuValue}
            onChange={(event, newValue) => {
              setBottomMenuValue(newValue);
              navigate('/feuillesderoute/' + newValue, { replace: true });
            }}>
            <BottomNavigationAction
              label="À faire"
              value="todo"
              icon={<FormatListBulletedOutlinedIcon />}
            />
            <BottomNavigationAction
              label="Terminé"
              value="complete"
              icon={<CheckIcon />}
            />
          </BottomNavigation>
        </ThemeProvider>
      );
    case 'feuillederoute':
      return (
        <ThemeProvider theme={theme}>
          <BottomNavigation
            showLabels
            sx={{
              width: '100%',
              position: 'fixed',
              bottom: '0px',
              zIndex: '200',
            }}
            value={bottomMenuValue}
            onChange={(event, newValue) => {
              setBottomMenuValue(newValue);
              navigate(`/feuillederoute/${params.id}/${newValue}`, {
                replace: true,
              });
            }}>
            <BottomNavigationAction
              label="Résumé"
              value="resume"
              icon={<LibraryBooksIcon />}
            />
            <BottomNavigationAction
              label="Ressources"
              value="ressources"
              icon={
                <Badge
                  badgeContent={
                    <CheckCircleFilled style={{ color: 'green' }} />
                  }
                  invisible={!ressourcesSaved}>
                  <LocalShippingIcon />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="Quantités"
              value="quantites"
              icon={
                <Badge
                  badgeContent={
                    <CheckCircleFilled style={{ color: 'green' }} />
                  }
                  invisible={!quantitesSaved}>
                  <TagIcon />
                </Badge>
              }
            />
            <BottomNavigationAction
              label="Peinture"
              value="peinture"
              icon={
                <Badge
                  badgeContent={
                    <CheckCircleFilled style={{ color: 'green' }} />
                  }
                  invisible={!peintureSaved}>
                  <FormatPaintIcon />
                </Badge>
              }
            />
          </BottomNavigation>
        </ThemeProvider>
      );
    default:
      return <></>;
  }
};

export default AppBottomNavigation;
