import { useState, createContext, useEffect, useRef } from "react";
import { notification } from "antd";
import { getToken, getUser } from "../modules/Users";
import { useParams } from "react-router-dom";
export const Context = createContext();

export default function ContextProvider({ children }) {
  const { id, section } = useParams();
  const [vh, setVh] = useState(window.visualViewport.height * 0.01);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerStatus, setHeaderStatus] = useState("");
  const [bottomMenuType, setBottomMenuType] = useState(null);
  const [bottomMenuValue, setBottomMenuValue] = useState(null);
  const [headerType, setHeaderType] = useState("");
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleMemoDrawer, setVisibleMemoDrawer] = useState(false);
  // const [headerRightIcon, setHeaderRightIcon] = useState(null);
  // const [headerRightFunction, setHeaderRightFunction] = useState(() => {});
  const feuilleDeRouteToSend = useRef({});
  const [ressourcesSaved, setRessourcesSaved] = useState(false);
  const [quantitesSaved, setQuantitesSaved] = useState(false);
  const [peintureSaved, setPeintureSaved] = useState(false);
  const [sendingFeuilleDeRoute, setSendingFeuilleDeRoute] = useState(false);
  const [loggedUser, setLoggedUser] = useState(undefined);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const successNotif = (message) => {
    notification.success({
      message: message,
      placement: "top",
    });
  };
  const errorNotif = (message) => {
    notification.error({
      message: message,
      placement: "top",
    });
  };
  const getContextUser = async () => {
    try {
      const response = await getUser();
      setLoggedUser(response.user);
    } catch (err) {
      console.log({ err });
      if (err.response.status === 401) {
        setLoggedUser(undefined);
        if (!window.location.pathname.startsWith("/login")) {
          window.location.href = `/login?ref=${encodeURI(
            window.location.pathname
          )}`;
        }
      }
    }
  };

  const stringToColour = (str) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  };

  useEffect(() => {
    if (!Object.keys(feuilleDeRouteToSend.current).length) {
      return;
    }
    const oldData = localStorage.getItem("feuilleDeRouteToSend")
      ? JSON.parse(localStorage.getItem("feuilleDeRouteToSend"))
      : {};
    const newData = {
      ...oldData,
      [feuilleDeRouteToSend.current.id]: feuilleDeRouteToSend.current,
    };
    localStorage.setItem("feuilleDeRouteToSend", JSON.stringify(newData));
  }, [feuilleDeRouteToSend.current]);

  return (
    <Context.Provider
      value={{
        vh,
        setVh,
        headerTitle,
        setHeaderTitle,
        headerStatus,
        setHeaderStatus,
        headerType,
        setHeaderType,
        bottomMenuType,
        setBottomMenuType,
        bottomMenuValue,
        setBottomMenuValue,
        visibleDrawer,
        setVisibleDrawer,
        // headerRightIcon,
        // setHeaderRightIcon,
        // headerRightFunction,
        // setHeaderRightFunction,
        feuilleDeRouteToSend,
        ressourcesSaved,
        setRessourcesSaved,
        quantitesSaved,
        setQuantitesSaved,
        peintureSaved,
        setPeintureSaved,
        sendingFeuilleDeRoute,
        setSendingFeuilleDeRoute,
        successNotif,
        errorNotif,
        getContextUser,
        loggedUser,
        stringToColour,
        confirmModalVisible,
        setConfirmModalVisible,
        visibleMemoDrawer,
        setVisibleMemoDrawer,
      }}
    >
      {children}
    </Context.Provider>
  );
}
