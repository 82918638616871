import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from './App';
import "./App.less";
import reportWebVitals from "./reportWebVitals";
import ContextProvider from "./utils/Context";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Navigation/Layout";
import FeuilleDeRoute from "./pages/FeuilleDeRoute";
import FeuillesDeRoute from "./pages/FeuillesDeRoute";
import Login from "./pages/Login";

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              path="/"
              element={<Navigate replace to="/feuillesderoute/todo" />}
            />
            <Route
              path="/feuillesderoute/:status"
              element={<FeuillesDeRoute />}
            />
            <Route
              path="feuillederoute/:id/:section"
              element={<FeuilleDeRoute />}
            />
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
