import { useParams } from "react-router-dom";
import { useEffect, useContext, useState, useRef } from "react";
import { getfeuillederoute, getemployeslist } from "../modules/Api";
import styled from "styled-components";
import { Context } from "../utils/Context";
import dayjs from "dayjs";
import Resume from "./FeuilleDeRoute/Resume";
import Ressources from "./FeuilleDeRoute/Ressources";
import Quantites from "./FeuilleDeRoute/Quantites";
import Peinture from "./FeuilleDeRoute/Peinture";
import ConfirmModal from "../components/ConfirmModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FeuilleDeRoute = () => {
  const { id, section } = useParams();
  const [feuilleDeRoute, setFeuilleDeRoute] = useState({});
  const [feuilleDeRouteQuantites, setFeuilleDeRouteQuantites] = useState({});
  const [feuilleDeRouteMainDoeuvres, setFeuilleDeRouteMainDoeuvres] = useState(
    {}
  );
  const [feuilleDeRouteVehicules, setFeuilleDeRouteVehicules] = useState({});
  const [feuilleDeRoutePeinture, setFeuilleDeRoutePeinture] = useState({});
  const [loading, setLoading] = useState(false);
  const [employesList, setEmployesList] = useState([]);
  const context = useContext(Context);
  const {
    setHeaderTitle,
    setHeaderStatus,
    setHeaderType,
    setBottomMenuType,
    setBottomMenuValue,
    feuilleDeRouteToSend,
    ressourcesSaved,
    setRessourcesSaved,
    quantitesSaved,
    setQuantitesSaved,
    peintureSaved,
    setPeintureSaved,
  } = context;

  useEffect(() => {
    setHeaderType("feuillederoute");
    getFeuilleDeRoute();
    setBottomMenuType("feuillederoute");
    setBottomMenuValue(section);
    setRessourcesSaved(false);
    setQuantitesSaved(false);
    setPeintureSaved(false);
    getEmployesList();
  }, []);
  const saveFeuilleDeRouteData = (categorie, value) => {
    if (categorie === "ressources") {
      setRessourcesSaved(value ? true : false);
    } else if (categorie === "quantites") {
      setQuantitesSaved(value ? true : false);
    } else if (categorie === "peinture") {
      setPeintureSaved(value ? true : false);
    }
    feuilleDeRouteToSend.current = {
      ...feuilleDeRouteToSend.current,
      [categorie]: value,
      id,
    };
    // console.log('FEUILLE DE ROUTE', feuilleDeRouteToSend.current);
  };
  const getFeuilleDeRoute = async () => {
    setLoading(true);
    const response = await getfeuillederoute(id);
    const oldData = localStorage.getItem("feuilleDeRouteToSend")
      ? JSON.parse(localStorage.getItem("feuilleDeRouteToSend"))
      : {};
    if (oldData[id] && response.feuilleDeRoute.status === "todo") {
      feuilleDeRouteToSend.current = oldData[id];
    }
    setFeuilleDeRoute(response.feuilleDeRoute);
    setFeuilleDeRouteQuantites(response.feuilleDeRouteQuantites);
    setFeuilleDeRouteMainDoeuvres(response.feuilleDeRouteMainDoeuvre);
    setFeuilleDeRouteVehicules(response.feuilleDeRouteVehicule);
    setFeuilleDeRoutePeinture(response.feuilleDeRoutePeinture);
    setHeaderTitle(
      `${response.feuilleDeRoute.noprojet} - ${response.feuilleDeRoute.descriptionprojet}`
    );
    setHeaderStatus(response.feuilleDeRoute.status);
    setLoading(false);
  };
  const getEmployesList = async () => {
    const employesList = await getemployeslist();
    if (employesList.success) setEmployesList(employesList.msg);
  };
  return (
    <Container>
      <Content>
        {section === "resume" && (
          <Resume
            feuilleDeRoute={feuilleDeRoute}
            feuilleDeRouteMainDoeuvres={feuilleDeRouteMainDoeuvres}
            feuilleDeRouteVehicules={feuilleDeRouteVehicules}
            loading={loading}
          />
        )}
        {section === "ressources" && (
          <Ressources
            feuilleDeRoute={feuilleDeRoute}
            feuilleDeRouteVehicules={feuilleDeRouteVehicules}
            feuilleDeRouteMainDoeuvres={feuilleDeRouteMainDoeuvres}
            loading={loading}
            saveFeuilleDeRouteData={saveFeuilleDeRouteData}
            ressourcesSaved={ressourcesSaved}
            feuilleDeRouteToSend={feuilleDeRouteToSend}
            employesList={employesList}
          />
        )}
        {section === "quantites" && (
          <Quantites
            loading={loading}
            feuilleDeRoute={feuilleDeRoute}
            feuilleDeRouteQuantites={feuilleDeRouteQuantites}
            saveFeuilleDeRouteData={saveFeuilleDeRouteData}
            quantitesSaved={quantitesSaved}
            feuilleDeRouteToSend={feuilleDeRouteToSend}
          />
        )}
        {section === "peinture" && (
          <Peinture
            loading={loading}
            feuilleDeRoute={feuilleDeRoute}
            feuilleDeRouteQuantites={feuilleDeRouteQuantites}
            feuilleDeRoutePeinture={feuilleDeRoutePeinture}
            saveFeuilleDeRouteData={saveFeuilleDeRouteData}
            peintureSaved={peintureSaved}
            feuilleDeRouteToSend={feuilleDeRouteToSend}
          />
        )}
      </Content>
      <ConfirmModal
        saveFeuilleDeRouteData={saveFeuilleDeRouteData}
        feuilleDeRoute={feuilleDeRoute}
      />
    </Container>
  );
};

export default FeuilleDeRoute;
