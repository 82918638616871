import { Space, Form, Input, Button, Spin, Dropdown, Menu } from "antd";
import { useEffect } from "react";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import MarquageCourteDuree from "./components/MarquageCourteDuree";
import MarquageMoyenneDuree from "./components/MarquageMoyenneDuree";
import MarquageLongueDuree from "./components/MarquageLongueDuree";
import MMA from "./components/MMA";

const Inventaire = ({
  loading,
  feuilleDeRoute,
  feuilleDeRouteQuantites,
  feuilleDeRoutePeinture,
  saveFeuilleDeRouteData,
  peintureSaved,
  feuilleDeRouteToSend,
}) => {
  const onFinish = (values) => {
    console.log("Success:", values);
    saveFeuilleDeRouteData("peinture", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(feuilleDeRoutePeinture);
    if (feuilleDeRouteToSend.current.peinture) {
      form.setFieldsValue(feuilleDeRouteToSend.current.peinture);
    }
    let existingLocalStorage =
      JSON.parse(localStorage.getItem("feuillesderoute")) || {};
    if (
      feuilleDeRoute.id &&
      existingLocalStorage[feuilleDeRoute.id]?.peinture &&
      Object.values(existingLocalStorage[feuilleDeRoute.id].peinture).length
    ) {
      form.setFieldsValue(existingLocalStorage[feuilleDeRoute.id].peinture);
    }
  }, [feuilleDeRoute, feuilleDeRoutePeinture]);
  const addMenu = (
    <Menu>
      <Menu.Item key="courteduree" onClick={() => {}}>
        Marquage courte durée
      </Menu.Item>
      <Menu.Item key="moyenneduree" onClick={() => {}}>
        Marquage moyenne durée
      </Menu.Item>
      <Menu.Item key="longueduree" onClick={() => {}}>
        Marquage longue durée
      </Menu.Item>
    </Menu>
  );
  return (
    <Form
      layout="vertical"
      scrollToFirstError={{ behavior: "smooth" }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      onFieldsChange={() => {
        let existingLocalStorage =
          JSON.parse(localStorage.getItem("feuillesderoute")) || {};
        existingLocalStorage = {
          ...existingLocalStorage,
          [feuilleDeRoute.id]: {
            ...existingLocalStorage[feuilleDeRoute.id],
            ["peinture"]: form.getFieldsValue(),
          },
        };
        localStorage.setItem(
          "feuillesderoute",
          JSON.stringify(existingLocalStorage)
        );
        saveFeuilleDeRouteData("peinture", null);
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {loading && (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 20,
          }}
        />
      )}
      {/* <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          marginBottom: 10,
        }}>
        <Dropdown overlay={addMenu} trigger={['click']}>
          <Button icon={<PlusOutlined />} type="primary" />
        </Dropdown>
      </div> */}
      <Space direction="vertical" style={{ width: "100%" }}>
        {feuilleDeRouteQuantites?.marquagecourteduree && (
          <MarquageCourteDuree
            form={form}
            feuilleDeRoutePeinture={feuilleDeRoutePeinture}
            feuilleDeRouteToSend={feuilleDeRouteToSend}
          />
        )}
        {feuilleDeRouteQuantites?.marquagemoyenneduree && (
          <MarquageMoyenneDuree
            form={form}
            feuilleDeRoutePeinture={feuilleDeRoutePeinture}
            feuilleDeRouteToSend={feuilleDeRouteToSend}
          />
        )}
        {feuilleDeRouteQuantites?.mma && (
          <MMA
            form={form}
            feuilleDeRoutePeinture={feuilleDeRoutePeinture}
            feuilleDeRouteToSend={feuilleDeRouteToSend}
          />
        )}
        {/* <MarquageLongueDuree /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <Form.Item noStyle style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={peintureSaved && <CheckOutlined />}
              style={{
                backgroundColor: peintureSaved && "green",
                borderColor: peintureSaved && "green",
              }}
            >
              {peintureSaved ? "Enregistré" : "Enregistrer"}
            </Button>
          </Form.Item>
        </div>
      </Space>
    </Form>
  );
};

export default Inventaire;
