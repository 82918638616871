import styled from "styled-components";
import {
  MenuOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { Button, notification } from "antd";
import { Context } from "../../utils/Context";
import { useContext, useEffect, useState } from "react";
import { sendfeuillederoute } from "../../modules/Api";
import { FaPaperPlane, FaHammer, FaCheck, FaStickyNote } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
const Container = styled.div`
  background-color: #001529;
  display: flex;
  height: 50px;
  position: fixed;
  width: 100%;
  align-items: center;
  padding: 0px 20px;
  color: white;
  font-size: 16px;
  z-index: 5;
`;
const LeftMenu = styled.div`
  flex: 0;
`;
const MiddleMenu = styled.div`
  flex: 1;
  text-align: center;
`;
const RightMenu = styled.div`
  flex: 0;
  text-align: right;
`;
const Header = () => {
  const [date, setDate] = useState(dayjs());
  const context = useContext(Context);
  const navigate = useNavigate();
  const {
    headerTitle,
    headerStatus,
    headerType,
    setVisibleDrawer,
    // headerRightIcon,
    // headerRightFunction,
    feuilleDeRouteToSend,
    ressourcesSaved,
    quantitesSaved,
    peintureSaved,
    sendingFeuilleDeRoute,
    setSendingFeuilleDeRoute,
    successNotif,
    errorNotif,
    confirmModalVisible,
    setConfirmModalVisible,
    visibleMemoDrawer,
    setVisibleMemoDrawer,
  } = context;
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    setDate(dayjs());
    const interval = setInterval(() => setDate(dayjs()), 1000);
    return () => clearInterval(interval);
  }, []);
  switch (headerType) {
    case "login":
      return (
        <Container>
          <LeftMenu>
            <Button
              icon={<MenuOutlined />}
              type="link"
              style={{ color: "white" }}
              onClick={() => {
                setVisibleDrawer(true);
              }}
            />
          </LeftMenu>
          <MiddleMenu>Se connecter</MiddleMenu>
          <RightMenu></RightMenu>
        </Container>
      );
    case "feuillesderoute":
      return (
        <Container>
          <LeftMenu>
            <Button
              icon={<MenuOutlined />}
              type="link"
              style={{ color: "white" }}
              onClick={() => {
                setVisibleDrawer(true);
              }}
            />
          </LeftMenu>
          <MiddleMenu>{date.format("LLLL:ss")}</MiddleMenu>
          <RightMenu
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              icon={<FaStickyNote />}
              type="link"
              style={{ color: "white" }}
              onClick={() => {
                setVisibleMemoDrawer(true);
              }}
            />
          </RightMenu>
        </Container>
      );
    case "feuillederoute":
      return (
        <Container>
          <LeftMenu>
            <Button
              icon={<ArrowLeftOutlined />}
              type="link"
              style={{ color: "white" }}
              onClick={() => {
                window.history.back();
              }}
            />
          </LeftMenu>
          <MiddleMenu
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
            }}
          >
            {headerTitle}{" "}
            {headerStatus === "todo" ? (
              <FaHammer style={{ color: "orange" }} />
            ) : (
              <FaCheck style={{ color: "green" }} />
            )}
          </MiddleMenu>
          <RightMenu style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Button
              icon={<FaStickyNote />}
              type="link"
              style={{ color: "white" }}
              onClick={() => {
                setVisibleMemoDrawer(true);
              }}
            />
            <Button
              icon={
                sendingFeuilleDeRoute ? <LoadingOutlined /> : <FaPaperPlane />
              }
              type="link"
              style={{ color: "white" }}
              onClick={async () => {
                let errors = [];
                if (!ressourcesSaved) {
                  errors.push("Enregistrez l'onglet Ressources");
                }
                if (!quantitesSaved) {
                  errors.push("Enregistrez l'onglet Quantités");
                }
                if (!peintureSaved) {
                  errors.push("Enregistrez l'onglet Peinture");
                }
                if (errors.length > 0) {
                  const key = `error${Date.now()}`;
                  const btn = (
                    <Button
                      type="danger"
                      // size="small"
                      onClick={() => notification.close(key)}
                    >
                      OK
                    </Button>
                  );
                  notification.error({
                    message: errors.join("\n"),
                    placement: "top",
                    duration: 0,
                    btn,
                    key,
                  });
                  return;
                }
                setConfirmModalVisible(true);
              }}
            />
          </RightMenu>
        </Container>
      );
    default:
      return null;
  }
};

export default Header;
