import {
  Card,
  Form,
  Input,
  Space,
  Button,
  InputNumber,
  Collapse,
  Row,
  Col,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
const { Panel } = Collapse;
const MarquageCourteDuree = ({
  form,
  feuilleDeRoutePeinture,
  feuilleDeRouteToSend,
}) => {
  const [openedTab, setOpenedTab] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    let _openedTab = [...openedTab];
    if (
      Object.keys(feuilleDeRoutePeinture || {}).length > 0 ||
      Object.keys(feuilleDeRouteToSend.current.peinture || {}).length > 0
    ) {
      if (
        feuilleDeRoutePeinture?.cd_reservoir_bille ||
        feuilleDeRoutePeinture?.cd_reservoir_blanc ||
        feuilleDeRoutePeinture?.cd_reservoir_jaune ||
        feuilleDeRouteToSend.current.peinture?.cd_reservoir_bille ||
        feuilleDeRouteToSend.current.peinture?.cd_reservoir_blanc ||
        feuilleDeRouteToSend.current.peinture?.cd_reservoir_jaune
      ) {
        _openedTab.push("1");
      }
      if (
        feuilleDeRoutePeinture?.cd_barril_bille?.length > 0 ||
        feuilleDeRoutePeinture?.cd_barril_blanc?.length > 0 ||
        feuilleDeRoutePeinture?.cd_barril_jaune?.length > 0 ||
        feuilleDeRouteToSend.current.peinture?.cd_barril_bille?.length > 0 ||
        feuilleDeRouteToSend.current.peinture?.cd_barril_blanc?.length > 0 ||
        feuilleDeRouteToSend.current.peinture?.cd_barril_jaune?.length > 0
      ) {
        _openedTab.push("2");
      }
      if (
        feuilleDeRoutePeinture?.cd_chaudiere_jaune_start ||
        feuilleDeRoutePeinture?.cd_chaudiere_jaune_finish ||
        feuilleDeRoutePeinture?.cd_chaudiere_jaune_total ||
        feuilleDeRoutePeinture?.cd_chaudiere_blanc_start ||
        feuilleDeRoutePeinture?.cd_chaudiere_blanc_finish ||
        feuilleDeRoutePeinture?.cd_chaudiere_blanc_total ||
        feuilleDeRoutePeinture?.cd_sac_bille_start ||
        feuilleDeRoutePeinture?.cd_sac_bille_finish ||
        feuilleDeRoutePeinture?.cd_sac_bille_total ||
        feuilleDeRoutePeinture?.cd_chaudiere_couleurspeciale?.length > 0 ||
        feuilleDeRouteToSend.current.peinture?.cd_chaudiere_jaune_start ||
        feuilleDeRouteToSend.current.peinture?.cd_chaudiere_jaune_finish ||
        feuilleDeRouteToSend.current.peinture?.cd_chaudiere_jaune_total ||
        feuilleDeRouteToSend.current.peinture?.cd_chaudiere_blanc_start ||
        feuilleDeRouteToSend.current.peinture?.cd_chaudiere_blanc_finish ||
        feuilleDeRouteToSend.current.peinture?.cd_chaudiere_blanc_total ||
        feuilleDeRouteToSend.current.peinture?.cd_sac_bille_start ||
        feuilleDeRouteToSend.current.peinture?.cd_sac_bille_finish ||
        feuilleDeRouteToSend.current.peinture?.cd_sac_bille_total ||
        feuilleDeRouteToSend.current.peinture?.cd_chaudiere_couleurspeciale
          ?.length > 0
      ) {
        _openedTab.push("3");
      }
    }
    setOpenedTab(_openedTab);
  }, [feuilleDeRoutePeinture, feuilleDeRouteToSend.current]);

  return (
    <Card
      title="Marquage Courte Durée"
      headStyle={{
        backgroundColor: "#001529",
        height: 50,
        display: "flex",
        alignItems: "center",
        color: "white",
      }}
      bodyStyle={{
        paddingTop: 15,
        paddingBottom: 15,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Collapse
        activeKey={openedTab}
        onChange={(e) => {
          setOpenedTab(e);
          let existingLocalStorage =
            JSON.parse(localStorage.getItem("feuillesderoute")) || {};
          if (!e.includes("1")) {
            form.resetFields([
              "cd_reservoir_jaune",
              "cd_reservoir_blanc",
              "cd_reservoir_bille",
            ]);
            delete existingLocalStorage[id].peinture.cd_reservoir_jaune;
            delete existingLocalStorage[id].peinture.cd_reservoir_blanc;
            delete existingLocalStorage[id].peinture.cd_reservoir_bille;
          }
          if (!e.includes("2")) {
            form.resetFields([
              "cd_barril_jaune",
              "cd_barril_blanc",
              "cd_barril_bille",
            ]);
            delete existingLocalStorage[id].peinture.cd_barril_jaune;
            delete existingLocalStorage[id].peinture.cd_barril_blanc;
            delete existingLocalStorage[id].peinture.cd_barril_bille;
          }
          if (!e.includes("3")) {
            form.resetFields([
              "cd_chaudiere_couleurspeciale",
              "cd_chaudiere_jaune_start",
              "cd_chaudiere_jaune_finish",
              "cd_chaudiere_jaune_total",
              "cd_chaudiere_blanc_start",
              "cd_chaudiere_blanc_finish",
              "cd_chaudiere_blanc_total",
              "cd_sac_bille_start",
              "cd_sac_bille_finish",
              "cd_sac_bille_total",
            ]);
            delete existingLocalStorage[id].peinture
              .cd_chaudiere_couleurspeciale;
            delete existingLocalStorage[id].peinture.cd_chaudiere_jaune_start;
            delete existingLocalStorage[id].peinture.cd_chaudiere_jaune_finish;
            delete existingLocalStorage[id].peinture.cd_chaudiere_jaune_total;
            delete existingLocalStorage[id].peinture.cd_chaudiere_blanc_start;
            delete existingLocalStorage[id].peinture.cd_chaudiere_blanc_finish;
            delete existingLocalStorage[id].peinture.cd_chaudiere_blanc_total;
            delete existingLocalStorage[id].peinture.cd_sac_bille_start;
            delete existingLocalStorage[id].peinture.cd_sac_bille_finish;
            delete existingLocalStorage[id].peinture.cd_sac_bille_total;
          }
          localStorage.setItem(
            "feuillesderoute",
            JSON.stringify(existingLocalStorage)
          );
        }}
      >
        <Panel header="Réservoir" key="1">
          <div style={{ display: "flex", gap: 15 }}>
            <Form.List name="cd_reservoir_jaune">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Jaune"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#fcba03",
                    color: "white",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      title={`Réservoir #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}
                    >
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List name="cd_reservoir_blanc">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Blanc"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#ffffff",
                    color: "black",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      title={`Réservoir #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}
                    >
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List name="cd_reservoir_bille">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Bille"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#94928d",
                    color: "white",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      title={`Réservoir #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}
                    >
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
          </div>
        </Panel>
        <Panel header="Barril" key="2">
          <div style={{ display: "flex", gap: 15 }}>
            <Form.List name="cd_barril_jaune">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Jaune"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#fcba03",
                    color: "white",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      title={`Barril #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}
                    >
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List name="cd_barril_blanc">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Blanc"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#ffffff",
                    color: "black",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      title={`Barril #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}
                    >
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
            <Form.List name="cd_barril_bille">
              {(fields, { add, remove }, { errors }) => (
                <Card
                  title="Bille"
                  extra={
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => add()}
                    />
                  }
                  style={{ flex: 1 }}
                  headStyle={{
                    backgroundColor: "#94928d",
                    color: "white",
                  }}
                  bodyStyle={{
                    padding: 1,
                  }}
                >
                  {fields.map((field, index) => (
                    <Card
                      title={`Barril #${index + 1}`}
                      extra={
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      }
                      bordered={false}
                      key={field.key}
                    >
                      <Form.Item
                        label="Niveau départ (pouces)"
                        name={[field.name, "start"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item
                        label="Niveau arrivée (pouces)"
                        name={[field.name, "finish"]}
                        rules={[{ required: true, message: "À remplir" }]}
                      >
                        <InputNumber style={{ width: "100%" }} min={0} />
                      </Form.Item>
                    </Card>
                  ))}
                </Card>
              )}
            </Form.List>
          </div>
        </Panel>
        <Panel header="Chaudière" key="3">
          <div style={{ display: "flex", marginBottom: 10, gap: 15 }}>
            <Card
              title="Jaune"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#fcba03",
                color: "white",
              }}
            >
              <Form.Item label="Nb départ" name="cd_chaudiere_jaune_start">
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
              <Form.Item label="Nb arrivée" name="cd_chaudiere_jaune_finish">
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
              {/* <Form.Item label="Total" name="cd_chaudiere_jaune_total">
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  rules={[{ required: true, message: 'À remplir' }]}
                />
              </Form.Item> */}
            </Card>
            <Card
              title="Blanc"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#ffffff",
                color: "black",
              }}
            >
              <Form.Item label="Nb départ" name="cd_chaudiere_blanc_start">
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
              <Form.Item label="Nb arrivée" name="cd_chaudiere_blanc_finish">
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
              {/* <Form.Item label="Total" name="cd_chaudiere_blanc_total">
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  rules={[{ required: true, message: 'À remplir' }]}
                />
              </Form.Item> */}
            </Card>
            <Card
              title="Bille"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#94928d",
                color: "white",
              }}
            >
              <Form.Item label="Nb départ" name="cd_sac_bille_start">
                <Input />
              </Form.Item>
              <Form.Item label="Nb arrivée" name="cd_sac_bille_finish">
                <Input />
              </Form.Item>
              {/* <Form.Item label="Total" name="cd_sac_bille_total">
                <Input rules={[{ required: true, message: 'À remplir' }]} />
              </Form.Item> */}
            </Card>
          </div>

          <Form.List name="cd_chaudiere_couleurspeciale">
            {(fields, { add, remove }, { errors }) => (
              <Card
                title="Couleur Spéciale"
                extra={
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => add()}
                  />
                }
                headStyle={{
                  backgroundColor: "#54b86f",
                  color: "white",
                }}
                style={{ flex: 1 }}
                bodyStyle={{
                  padding: 1,
                }}
              >
                <Row>
                  {fields.map((field, index) => (
                    <Col span={8} gutter={[15, 15]} key={field.key}>
                      <Card
                        title={`Couleur #${index + 1}`}
                        extra={
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => remove(field.name)}
                          />
                        }
                        bordered={false}
                        key={field.key}
                      >
                        <Form.Item
                          label="Couleur"
                          name={[field.name, "couleur"]}
                          rules={[{ required: true, message: "À remplir" }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Nb départ"
                          name={[field.name, "start"]}
                          rules={[{ required: true, message: "À remplir" }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Nb arrivée"
                          name={[field.name, "finish"]}
                          rules={[{ required: true, message: "À remplir" }]}
                        >
                          <Input />
                        </Form.Item>
                        {/* <Form.Item
                          label="Total"
                          name={[field.name, 'total']}
                          rules={[{ required: true, message: 'À remplir' }]}>
                          <Input />
                        </Form.Item> */}
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
            )}
          </Form.List>
        </Panel>
      </Collapse>
    </Card>
  );
};

export default memo(MarquageCourteDuree);
