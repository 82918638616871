import {
  Card,
  Form,
  Input,
  Space,
  Button,
  InputNumber,
  Collapse,
  Row,
  Col,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import numeral from "numeral";
const { Panel } = Collapse;
const MarquageMoyenneDuree = ({ form }) => {
  const { id } = useParams();
  return (
    <Card
      title="Marquage Moyenne Durée"
      headStyle={{
        backgroundColor: "#001529",
        height: 50,
        display: "flex",
        alignItems: "center",
        color: "white",
      }}
      bodyStyle={{
        paddingTop: 15,
        paddingBottom: 15,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Collapse
        defaultActiveKey={["1"]}
        onChange={(e) => {
          let existingLocalStorage =
            JSON.parse(localStorage.getItem("feuillesderoute")) || {};
          if (!e.includes("1")) {
            form.resetFields([
              "md_reservoir_jaune_lot",
              "md_reservoir_jaune_total",
              "md_reservoir_blanc_lot",
              "md_reservoir_blanc_total",
              "md_reservoir_durcisseur_lot",
              "md_reservoir_durcisseur_total",
              "md_reservoir_bille",
            ]);
            delete existingLocalStorage[id].peinture.md_reservoir_jaune_lot;
            delete existingLocalStorage[id].peinture.md_reservoir_jaune_total;
            delete existingLocalStorage[id].peinture.md_reservoir_blanc_lot;
            delete existingLocalStorage[id].peinture.md_reservoir_blanc_total;
            delete existingLocalStorage[id].peinture
              .md_reservoir_durcisseur_lot;
            delete existingLocalStorage[id].peinture
              .md_reservoir_durcisseur_total;
            delete existingLocalStorage[id].peinture.md_reservoir_bille;
          }
        }}
      >
        <Panel header="Réservoir" key="1">
          <div style={{ display: "flex", marginBottom: 10, gap: 15 }}>
            <Card
              title="Jaune"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#fcba03",
                color: "white",
              }}
            >
              <Form.Item label="# Lot" name="md_reservoir_jaune_lot">
                <Input />
              </Form.Item>
              <Form.Item
                label="Total sur ticket (L)"
                name="md_reservoir_jaune_total"
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: "À remplir",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  onChange={() => {
                    form.setFieldValue(
                      "md_reservoir_durcisseur_total",
                      numeral(
                        ((form.getFieldValue("md_reservoir_jaune_total") ?? 0) +
                          (form.getFieldValue("md_reservoir_blanc_total") ??
                            0) +
                          (form.getFieldValue("md_reservoir_couleurspeciale")
                            ? form
                                .getFieldValue("md_reservoir_couleurspeciale")
                                .reduce(
                                  (prev, curr) =>
                                    prev +
                                    (curr.total ? parseInt(curr.total) : 0),
                                  0
                                )
                            : 0)) /
                          3
                      ).format("0.[00]")
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues["md_reservoir_jaune_total"] !==
                  currentValues["md_reservoir_jaune_total"]
                }
              >
                {({ getFieldValue }) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      Peinture:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {numeral(
                          ((getFieldValue("md_reservoir_jaune_total") ?? 0) *
                            2) /
                            3
                        ).format("0.[00]")}{" "}
                        L
                      </span>
                    </div>
                    <div>
                      Durcisseur:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {numeral(
                          ((getFieldValue("md_reservoir_jaune_total") ?? 0) *
                            1) /
                            3
                        ).format("0.[00]")}{" "}
                        L
                      </span>
                    </div>
                  </div>
                )}
              </Form.Item>
            </Card>
            <Card
              title="Blanc"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#ffffff",
                color: "black",
              }}
            >
              <Form.Item label="# Lot" name="md_reservoir_blanc_lot">
                <Input />
              </Form.Item>

              <Form.Item
                label="Total sur ticket (L)"
                style={{ marginBottom: 0 }}
                name="md_reservoir_blanc_total"
                rules={[
                  {
                    required: true,
                    message: "À remplir",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  onChange={() => {
                    form.setFieldValue(
                      "md_reservoir_durcisseur_total",
                      numeral(
                        ((form.getFieldValue("md_reservoir_jaune_total") ?? 0) +
                          (form.getFieldValue("md_reservoir_blanc_total") ??
                            0) +
                          (form.getFieldValue("md_reservoir_couleurspeciale")
                            ? form
                                .getFieldValue("md_reservoir_couleurspeciale")
                                .reduce(
                                  (prev, curr) =>
                                    prev +
                                    (curr.total ? parseInt(curr.total) : 0),
                                  0
                                )
                            : 0)) /
                          3
                      ).format("0.[00]")
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues["md_reservoir_blanc_total"] !==
                  currentValues["md_reservoir_blanc_total"]
                }
              >
                {({ getFieldValue }) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      Peinture:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {numeral(
                          ((getFieldValue("md_reservoir_blanc_total") ?? 0) *
                            2) /
                            3
                        ).format("0.[00]")}{" "}
                        L
                      </span>
                    </div>
                    <div>
                      Durcisseur:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {numeral(
                          ((getFieldValue("md_reservoir_blanc_total") ?? 0) *
                            1) /
                            3
                        ).format("0.[00]")}{" "}
                        L
                      </span>
                    </div>
                  </div>
                )}
              </Form.Item>
            </Card>
            <Card
              title="Durcisseur"
              style={{ flex: 1 }}
              headStyle={{
                backgroundColor: "#000000",
                color: "white",
              }}
            >
              <Form.Item label="# Lot" name="md_reservoir_durcisseur_lot">
                <Input />
              </Form.Item>
              <Form.Item
                label="Total (L)"
                name="md_reservoir_durcisseur_total"
                style={{ marginBottom: 0 }}
              >
                <Input
                  bordered={false}
                  readOnly
                  style={{ color: "orange", fontWeight: "bold" }}
                />
              </Form.Item>
            </Card>
          </div>
          <Form.List name="md_reservoir_couleurspeciale">
            {(fields, { add, remove }, { errors }) => (
              <Card
                title="Couleur Spéciale"
                extra={
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => add()}
                  />
                }
                headStyle={{
                  backgroundColor: "#54b86f",
                  color: "white",
                }}
                style={{ flex: 1 }}
                bodyStyle={{
                  padding: 1,
                }}
              >
                <Row>
                  {fields.map((field, index) => (
                    <Col span={8} gutter={[15, 15]} key={field.key}>
                      <Card
                        title={`Couleur #${index + 1}`}
                        extra={
                          <Button
                            icon={<MinusOutlined />}
                            type="danger"
                            onClick={() => {
                              remove(field.name);
                              form.setFieldValue(
                                "md_reservoir_durcisseur_total",
                                numeral(
                                  ((form.getFieldValue(
                                    "md_reservoir_jaune_total"
                                  ) ?? 0) +
                                    (form.getFieldValue(
                                      "md_reservoir_blanc_total"
                                    ) ?? 0) +
                                    (form.getFieldValue(
                                      "md_reservoir_couleurspeciale"
                                    )
                                      ? form
                                          .getFieldValue(
                                            "md_reservoir_couleurspeciale"
                                          )
                                          .reduce(
                                            (prev, curr) =>
                                              prev +
                                              (curr.total
                                                ? parseInt(curr.total)
                                                : 0),
                                            0
                                          )
                                      : 0)) /
                                    3
                                ).format("0.[00]")
                              );
                            }}
                          />
                        }
                        bordered={false}
                        key={field.key}
                      >
                        <Form.Item
                          label="Couleur"
                          name={[field.name, "couleur"]}
                          rules={[{ required: true, message: "À remplir" }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item label="# Lot" name={[field.name, "lot"]}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Total (L)"
                          name={[field.name, "total"]}
                          rules={[{ required: true, message: "À remplir" }]}
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            onChange={() => {
                              form.setFieldValue(
                                "md_reservoir_durcisseur_total",
                                numeral(
                                  ((form.getFieldValue(
                                    "md_reservoir_jaune_total"
                                  ) ?? 0) +
                                    (form.getFieldValue(
                                      "md_reservoir_blanc_total"
                                    ) ?? 0) +
                                    form
                                      .getFieldValue(
                                        "md_reservoir_couleurspeciale"
                                      )
                                      .reduce(
                                        (prev, curr) =>
                                          prev +
                                          (curr.total
                                            ? parseInt(curr.total)
                                            : 0),
                                        0
                                      )) /
                                    3
                                ).format("0.[00]")
                              );
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.md_reservoir_couleurspeciale[
                              field.name
                            ] &&
                            currentValues.md_reservoir_couleurspeciale[
                              field.name
                            ] &&
                            prevValues.md_reservoir_couleurspeciale[field.name]
                              .total !==
                              currentValues.md_reservoir_couleurspeciale[
                                field.name
                              ].total
                          }
                        >
                          {({ getFieldValue }) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                Peinture:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {numeral(
                                    ((getFieldValue([
                                      "md_reservoir_couleurspeciale",
                                      field.name,
                                      "total",
                                    ]) ?? 0) *
                                      2) /
                                      3
                                  ).format("0.[00]")}{" "}
                                  L
                                </span>
                              </div>
                              <div>
                                Durcisseur:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {numeral(
                                    ((getFieldValue([
                                      "md_reservoir_couleurspeciale",
                                      field.name,
                                      "total",
                                    ]) ?? 0) *
                                      1) /
                                      3
                                  ).format("0.[00]")}{" "}
                                  L
                                </span>
                              </div>
                            </div>
                          )}
                        </Form.Item>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
            )}
          </Form.List>
          <Form.List
            name="md_reservoir_bille"
            initialValue={[
              {
                depart_gauche: undefined,
                depart_droite: undefined,
                arrivee_gauche: undefined,
                arrivee_droite: undefined,
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <Card
                title="Bille"
                extra={
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => add()}
                  />
                }
                style={{
                  flex: 1,
                  marginTop: 10,
                }}
                headStyle={{
                  backgroundColor: "#94928d",
                  color: "white",
                }}
                bodyStyle={{
                  padding: 1,
                }}
              >
                {fields.map((field, index) => (
                  <Card
                    title={`Réservoir #${index + 1}`}
                    extra={
                      index >= 1 && (
                        <Button
                          icon={<MinusOutlined />}
                          type="danger"
                          onClick={() => remove(field.name)}
                        />
                      )
                    }
                    bordered={false}
                    key={field.key}
                  >
                    <div style={{ display: "flex", gap: 50 }}>
                      <Form.Item
                        style={{ flex: 1, margin: 0 }}
                        label="Niveau départ"
                      >
                        <Space size={20}>
                          <Space>
                            <span>G:</span>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "À remplir",
                                },
                              ]}
                              name={[field.name, "depart_gauche"]}
                              noStyle
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Space>
                          <Space>
                            <span>D:</span>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "À remplir",
                                },
                              ]}
                              name={[field.name, "depart_droite"]}
                              noStyle
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Space>
                        </Space>
                      </Form.Item>
                      <Form.Item
                        style={{ flex: 1, margin: 0 }}
                        label="Niveau arrivé"
                      >
                        <Space size={20}>
                          <Space>
                            <span>G:</span>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "À remplir",
                                },
                              ]}
                              name={[field.name, "arrivee_gauche"]}
                              noStyle
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Space>
                          <Space>
                            <span>D:</span>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "À remplir",
                                },
                              ]}
                              name={[field.name, "arrivee_droite"]}
                              noStyle
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Space>
                        </Space>
                      </Form.Item>
                    </div>
                  </Card>
                ))}
              </Card>
            )}
          </Form.List>
        </Panel>
      </Collapse>
    </Card>
  );
};

export default MarquageMoyenneDuree;
