import { useState, useContext, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Row,
  Col,
  Upload,
  Spin,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Context } from "../utils/Context";
import { sendfeuillederoute } from "../modules/Api";
import { Dropbox } from "dropbox";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
const { TextArea } = Input;

const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
// const ACCESS_TOKEN =
//   'sl.BUG_4Qb2jNfMgd994mhQ5Bfxml1W32R8WqjCjYfPorTyw2RHyCSTH2zOldMZrsnYru6MqqKhd8ntm1dmbnMUdZZBVbnjk15U7Bgc6IYtAQwLUs0vD-7Ft5apunXC4vAobfe4CeIrthms';
const dbx = new Dropbox({
  // accessToken: ACCESS_TOKEN,
  clientId: "mkalsqcz9p2jvsd",
  clientSecret: "b9juaeo5pg18n1m",
  refreshToken:
    "n2T14aP4b_0AAAAAAAAAAV7cQAu7gIEZH0EoX5RRTG5ZmzRTzxqCzxsvpH545Pnr",
});

const CustomTimeInput = ({ ...restProps }) => {
  return (
    <Input
      type="time"
      {...restProps}
      style={{ width: "100%", height: 34 }}
      step="900"
    />
  );
};
const CustomFormItem = ({ name, label, initialValue }) => {
  return (
    <Form.Item
      label={label}
      rules={[
        {
          required: true,
          message: "À remplir",
        },
      ]}
      name={name}
      initialValue={initialValue ? initialValue : ""}>
      <CustomTimeInput onChange={() => {}} />
    </Form.Item>
  );
};
const ConfirmModal = ({ saveFeuilleDeRouteData, feuilleDeRoute }) => {
  const context = useContext(Context);
  const [fileList, setFileList] = useState([]);
  const {
    confirmModalVisible,
    setConfirmModalVisible,
    sendingFeuilleDeRoute,
    setSendingFeuilleDeRoute,
    feuilleDeRouteToSend,
    successNotif,
    errorNotif,
  } = context;
  const handleOk = () => {
    form.validateFields().then(async (values) => {
      console.log("Success: ", values);
      if (fileList.length === 0) {
        errorNotif("Veuillez ajouter des photos");
        return;
      }
      saveFeuilleDeRouteData("travauxcompletes", values.travauxcompletes);
      saveFeuilleDeRouteData(
        "heurereellearriveechantier",
        values.heurereellearriveechantier
      );
      saveFeuilleDeRouteData(
        "heurereelledepartchantier",
        values.heurereelledepartchantier
      );
      saveFeuilleDeRouteData("temperature", values.temperature);
      saveFeuilleDeRouteData("meteo", values.meteo);
      saveFeuilleDeRouteData("chausseehumiditee", values.chausseehumiditee);
      saveFeuilleDeRouteData("chausseeproprete", values.chausseeproprete);
      saveFeuilleDeRouteData(
        "commentaireschantier",
        values.commentaireschantier
      );
      saveFeuilleDeRouteData("nomclient", values.nomclient);
      saveFeuilleDeRouteData("numclient", values.numclient);
      saveFeuilleDeRouteData("emailclient", values.emailclient);
      saveFeuilleDeRouteData("photos", [...fileList]);
      // console.log('feuilleDeRouteToSend', feuilleDeRouteToSend.current);
      setSendingFeuilleDeRoute(true);
      let response = await sendfeuillederoute(feuilleDeRouteToSend.current);
      if (response.success) {
        successNotif("La feuille de route a bien été envoyée!");
        navigate("/");
        feuilleDeRouteToSend.current = {};
      } else {
        errorNotif(response.error);
      }
      setSendingFeuilleDeRoute(false);
      setConfirmModalVisible(false);
    });
  };
  const handleCancel = () => {
    if (sendingFeuilleDeRoute) return;
    setConfirmModalVisible(false);
  };
  const upload = async (selectedFileList, file, path) => {
    if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
      // File is smaller than 150 Mb - use filesUpload API
      try {
        await dbx.filesUpload({ path, contents: file });
        let sharedLink = (
          await dbx.sharingListSharedLinks({ path })
        ).result.links[0]?.url.replace(
          "www.dropbox.com",
          "dl.dropboxusercontent.com"
        );
        if (!sharedLink) {
          sharedLink = (
            await dbx.sharingCreateSharedLinkWithSettings({ path })
          ).result.url.replace("www.dropbox.com", "dl.dropboxusercontent.com");
        }
        console.log({ selectedFileList });
        const newFileList = [...fileList, ...selectedFileList];
        const index = newFileList.indexOf(file);
        newFileList[index].status = "done";
        newFileList[index].url = sharedLink;
        setFileList(cleanFileList(newFileList));
      } catch (err) {
        console.error(err);
        const newFileList = [...fileList, ...selectedFileList];
        const index = newFileList.indexOf(file);
        newFileList[index].status = "error";
        setFileList(cleanFileList(newFileList));
      }
    }
  };
  const cleanFileList = (fileList) => {
    return fileList.filter((e, index) => {
      return (
        index === fileList.indexOf(fileList.filter((d) => d.url === e.url)[0])
      );
    });
  };
  const [form] = Form.useForm();
  const navigate = useNavigate();
  return (
    <Modal
      title="Envoyer la feuille de route"
      visible={confirmModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="Annuler"
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
          disabled={
            sendingFeuilleDeRoute ||
            fileList.filter((e) => e.status === "uploading").length > 0
          }>
          Annuler
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={fileList.filter((e) => e.status === "uploading").length > 0}
          loading={sendingFeuilleDeRoute}
          onClick={handleOk}>
          Envoyer
        </Button>,
      ]}>
      <Form
        layout="vertical"
        form={form}
        scrollToFirstError={{ behavior: "smooth" }}
        onFieldsChange={() => {}}
        autoComplete="off">
        <Form.Item
          label="Travaux terminés ?"
          name="travauxcompletes"
          rules={[
            {
              required: true,
              message: "À remplir",
            },
          ]}>
          <Radio.Group>
            <Radio value="oui"> Oui </Radio>
            <Radio value="non"> Non </Radio>
          </Radio.Group>
        </Form.Item>
        <Row gutter={20}>
          <Col span={12}>
            <CustomFormItem
              label="Heure d'arrivée au chantier :"
              name="heurereellearriveechantier"
            />
          </Col>
          <Col span={12}>
            <CustomFormItem
              label="Heure de départ du chantier :"
              name="heurereelledepartchantier"
            />
          </Col>
        </Row>
        <Form.Item
          label="Température (°C)"
          name="temperature"
          rules={[
            {
              required: true,
              message: "À remplir",
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Météo"
          name="meteo"
          rules={[
            {
              required: true,
              message: "À remplir",
            },
          ]}>
          <Radio.Group
            options={["Ciel Dégagé", "Nuageux", "Pluie", "Neige"]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item
          label="Humidité de la chausée"
          name="chausseehumiditee"
          rules={[
            {
              required: true,
              message: "À remplir",
            },
          ]}>
          <Radio.Group
            options={["Sèche", "Humide", "Saturée d'eau"]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item
          label="Propreté de la chausée"
          name="chausseeproprete"
          rules={[
            {
              required: true,
              message: "À remplir",
            },
          ]}>
          <Radio.Group
            options={["Propre", "Poussiéreuse", "Non Nettoyée"]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <div style={{ display: "flex", gap: 4 }}>
          <span style={{ color: "red" }}>*</span>
          <span>Photos :</span>
        </div>
        <Upload
          style={{}}
          multiple
          onRemove={async (file) => {
            const newFileList = [...fileList];
            const index = newFileList.indexOf(file);
            newFileList[index].status = "error";
            setFileList([...newFileList]);
            const path =
              "/" +
              feuilleDeRoute.noprojet +
              " - " +
              feuilleDeRoute.descriptionprojet +
              "/" +
              "photos/" +
              dayjs(feuilleDeRoute.date, "YYYYMMDD").format("YYYY-MM-DD") +
              "/" +
              file.name;
            await dbx.filesDeleteV2({ path });
            setFileList(fileList.filter((e) => e.url !== file.url));
          }}
          beforeUpload={async (file, selectedFileList) => {
            const path =
              "/" +
              feuilleDeRoute.noprojet +
              "/" +
              "photos/" +
              dayjs(feuilleDeRoute.date, "YYYYMMDD").format("YYYY-MM-DD") +
              "/" +
              file.name;
            file.status = "uploading";
            console.log({ selectedFileList });
            setFileList([...selectedFileList, ...fileList]);
            upload([...selectedFileList], file, path);
            return false;
          }}
          fileList={fileList}
          // iconRender={(file) => {
          //   if (file.status === 'uploading') return <Spin />;
          //   // if (file.status === 'done') {
          //   //   const index = fileList.findIndex((e) => e.url === file.url);
          //   //   return (
          //   //     <Checkbox
          //   //       checked={fileList[index].checked}
          //   //       onChange={(e) => {
          //   //         informations.current.fichiers[index].checked =
          //   //           e.target.checked;
          //   //         setFileList([...informations.current.fichiers]);
          //   //       }}
          //   //     />
          //   //   );
          //   // }
          //   return null;
          // }}
        >
          <Button icon={<UploadOutlined />}>Ajouter une photo</Button>
        </Upload>
        <Form.Item
          label="Nom du client :"
          name={"nomclient"}
          style={{ marginTop: 24 }}>
          <Input />
        </Form.Item>
        <Form.Item label="Numéro du client :" name={"numclient"}>
          <Input />
        </Form.Item>
        <Form.Item label="Courriel du client :" name={"emailclient"}>
          <Input />
        </Form.Item>
        <Form.Item label="Commentaires :" name={"commentaireschantier"}>
          <TextArea autoSize />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ConfirmModal;
